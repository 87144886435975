import React from 'react';

import '../App.css';

import'./WebPages.css';


function WebPages(props) {
    return(
       <div className="hero-container">
           <video src="/videos/video-2.mp4" autoPlay loop muted></video>
           <h2 id="top" className="info-box-header">{props.head}</h2>
           <div className="info-box">
               
              <p>{props.p1}</p>
              <p>{props.p2}</p>
             
              <ul><li>{props.li1}</li>
              <li>{props.li2}</li>
              <li>{props.li3}</li>
              <li>{props.li4}</li>
              <li>{props.li5}</li>
              
              </ul>
              <p>{props.p3}</p>
              <p>{props.p4}</p>
           </div>

           </div>

    );
    
}

export default WebPages;