import React from 'react';
import '../../App.css';
import Footer from '../Footer';

import WebPages from "../WebPages"


function Advanced() {
return(

<>

    <WebPages
    head = "A Web App - What is it?"
    p1 = "A Web App is any application that can be used on the web. They Usually have database interaction but not always. I create a variety of web apps from e-commerce stores to blogs."
    p2 = "Some things a Web App is capable of:"
    li1 = "Selling goods online"
    li2 = "Writing a blog or reference information which visitors can search your website for."
    li3 = "The ability for clients to contact you through a web form."
    li4 = "A back office system where clients can log on to retrieve privilidged information or an admin can change and update information."
    li5 = "An Online booking system for a bed and breakfast."
    p3 = "A Web App is ideal for people who want to carry out business transactions on the web or for an online business. Also for people who belong to a community who want to share information." 
    
    />
    
    <Footer />
    </>
);

}
export default Advanced;