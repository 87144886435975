import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import WebPages from "../WebPages"


function WordPress() {
return(
<>
    <WebPages
    head = "WordPress Sites - Managed and UnManaged"
    p1 = "WordPress sites are very popular and come with a variety of themes paid and unpaid. This gives the creator a lot of flexibility."
    p2 = "A WordPress site has the following advantages:"
    li1 = "It has a built in Admin function for you to be able to manage your own content."
    li2 = "Save for hosting is a free platform."
    li3 = "It is possible to create custom themes with WordPress."
    li4 = "Tools to manage seo built in."
    li5 = "A great platform for blogging."
    p3 = "We offer an unmanaged service which means we will install WordPress on your site and give you the log in details for you to start your wordpress adventure." 
    p4 = "Or a managed service where we will build the site for you and update it. And also a service where we can design a WordPress template that you or we can manage."
    />
    <Footer />
    </>
);

}
export default WordPress;