import React from 'react';

import '../App.css';
import Button from './Button';
import'./HeroSection.css';


function HeroSection() {
    return(
       <div className="hero-container">
           <video src="/videos/video-2.mp4" autoPlay loop muted></video>
           
           <h1>The Coding girls</h1>
           <p>Making the web work for you</p>
           <div className="hero-btns">
          
               <Button className='btns' 
               buttonStyle='btn--outline'
               page='/webDesign'
               buttonSize='btn--large'>
                   WEB DESIGN
               </Button>
               <Button className='btns' 
               page='/Hosting' 
               buttonStyle='btn--primary'
               buttonSize='btn--large'>
                 
                   HOSTING 
               </Button>
           </div>
       </div> 

    );
    
}

export default HeroSection;