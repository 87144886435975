import React from 'react';
import '../../App.css';
import Footer from '../Footer';

import WebPages from "../WebPages"


function About() {
return(

<>

    <WebPages
    head = "About The Coding Girls"
    p1 = "My Name is Maria and I have been designing websites for 10 years, starting with basic html sites back in 2011 to advance php sites today."
    p2 = "Some web technolgies and interfaces  I have experience with include but not limited to: "
    li1 = "The basic web languages of html, javascript and css. "
    li2 = "The server side languages of php, c#, python for building advanced web apps."
    li3 = "The Frameworks/libraries of reactjs, jquery, bootstrap and sass for front end development. "
    li4 = "MVC Frameworks both in php and c#."
    li5 = "Creating Desktop Apps with Python and C#."
    p3 = "If you have an idea that you want to turn into a reality get in touch." 
    
    />
    
    <Footer />
    </>
);

}
export default About;