import React from 'react';
import '../../App.css';
import'../Cards.css';
import CardItem from '../CardItem';
import Footer from '../Footer';

function WebDesign() {

return(
    <>


<div className="cards">
<h1>Web Design Services</h1>
<div className="cards__container">
    <div className="cards__wrapper">
        <ul className="cards__items">
            
             <CardItem 
            title="WordPress Site" 
            src="images/wordPress.jpg"
            text="Built and Managed WordPress sites.We will build and manage your WordPress site."
            label="From €150 "
            path="/Wordpress"
            />
            <CardItem 
            title = "Basic Web Sites"
            src="images/Html.jpg"
            alt="Web Design"
            text="Basic site for information purposes, ideal for companies who are using the website as a point of contact or to get information to their clients."
            label="From €150 per year"
            path="/Basic"
            />

       
            <CardItem 
            title = "Web Apps"
            src="images/WebApps.jpg"
            text="Web Apps for businesses who need to store info on databases and let their customers/employees retrieve it. Other uses can include logins, online shops, back office functionality etc."
            label="From €250"
            path="/Advanced"
            />
            </ul></div>
</div>

        </div>
<Footer />
</>
);
}
export default WebDesign;

