import React from 'react';
import { Link } from 'react-router-dom';
import '../../App.css';
import Footer from '../Footer';
import WebPages from "../WebPages"


function Contact() {
return(
<>
    <WebPages
    head = "Contact Me"
    p1 = "I can offer you a personalised service and design a web app/site to your exact specifications."
    p2 = "So contact me below for a free no obligation quote:"
    li1 = "maria@thecodingirls.com"
    li2 = "Telephone: +33 766013147"
    p4 = "Websites are like an online shop front to your business. You can make them look however you like to better sell your product/service."
  
    />
    <Link to = "/facebook" className="link">Find me on Facebook</Link>
    <Footer />
    </>
);

}
export default Contact;