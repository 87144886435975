import React from 'react';
import '../../App.css';

import HeroSection from '../HeroSection';

import Footer from '../Footer';
import Modal from '../Modal';


function Home() {
  
    
        return(
            <>
            
            <Modal />
            <HeroSection />
          
            <Footer />
            </>
        );  
   
}
export default Home;