import React from 'react';
import '../../App.css';
import '../Cards.css';
import CardItem from '../CardItem';
import Footer from '../Footer';

function Hosting() {

return(
    <>

<div className="cards">
<h1>Hosting Services</h1>
<div className="cards__container">
    <div className="cards__wrapper">
        <ul className="cards__items">
            
             <CardItem 
            title="WordPress Site" 
            src="images/wordPress.jpg"
            text="Host and Manange your own WordPress site. We will set up your wordpress site for you to manage."
            label="From €99 "
            path="/WordPress"
            />

            <CardItem 
            title = "Host your Website"
            src="images/hosting.jpg"
            alt="Web Design"
            text="Host your own website on our Server. Build your site and upload it to our server."
            label="From €50 per year"
            path="/Hoster"
            />

        
            <CardItem 
            title = "SEO"
            src="images/seo.jpg"
            text="Get your website to where it belongs with our SEO service. Using a variety of techniques to get your site higher up on the search pages."
            label="From €25"
            path="/Seo"
            />
            </ul></div>
</div>

        </div>
<Footer />
</>
);
}
export default Hosting;

