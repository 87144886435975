import React  from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import Cookies from 'universal-cookie';

import'./Modal.css';


function Modal(props){
    
    const[click, setClick] = useState(0);
      const[ colorStyle, setColorStyle] = useState( {display:"block"})
      
      const cookies = new Cookies();
      
    if(cookies == null) {
    
    return (
        
        <div className="modal">
        <div className="content" style={colorStyle} >
        <h1 className="header" >Cookies</h1>
        <p className="actions">This site uses cookies:  <Link to="/Cookies">More Info</Link></p>
       
        <button className="actions" onClick={() => {setColorStyle({display:"none"});setClick(click + 1); cookies.set('user', 'webUser', { path: '/Cookies' });}}>Accept</button>
        
     
    </div>
       </div>
);
    } else {
        return ("");}
}
    export default Modal;