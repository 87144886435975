import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import WebPages from "../WebPages"


function Seo() {
return(
<>
    <WebPages
    head = "Search Engine Optimisation - Getting noticed on the web"
    p1 = "Getting on to the front page of search engines is paramount if you want to gain customers from your website."
    p2 = "To get good organic (unpaid for) reach is down to a number of factors:"
    li1 = "Updated Content."
    li2 = "Use of Keywords."
    li3 = "Social media links."
    li4 = "Back links from other sites."
    li5 = "Google Reviews linked to your site."
    p3 = "Using the above techniques will help raise your ranks in the search engines, however these are just a few things that can be done - this list is not exhaustive by any means." />
    <Footer />
    </>
);

}
export default Seo;