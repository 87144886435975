import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import WebPages from "../WebPages"


function Hoster() {
return(
<>
    <WebPages
    head = "Hosting Your Website"
    p1 = "Creating your own website can be fun and a learning experience."
    p2 = "The advantages of hosting a website with us:"
    li1 = "Manage Your own content."
    li2 = "A dedicated server control panel for you to update your site."
    li3 = "The flexibilty to change your site when you need to."
    li4 = "No dependent platform to rely on (depending on your choice of build)."
    li5 = "Manage your links to social media and seo."
    p3 = "Running and updating a website can be time consuming but if you know the languages well and the framework it can be a lot of fun." />
    <Footer />
    </>
);

}
export default Hoster;