import React from 'react';

import CardItem from '../CardItem';
import '../Cards.css';

function Examples() {
    return(

        
        <div className="cards">
<h1>Examples of previous work</h1>
<div className="cards__container">
    <div className="cards__wrapper">
        <ul className="cards__items">
            <CardItem 
            src="images/hmis.jpg"
            text="This website was built for a client who wanted to display his completed work as well as sell some of the finished products."
            label="HandMadeinStotfold.com"
            path="/handmadeinstotfold"
            />
             <CardItem 
            src="images/mlb.png"
            text="This website was built as an information portal to it's customers and during the pandemic to offer customers an online ordering service."
            label="Making Life Beautiful"
            path="/MakingLifeBeautiful"
            />

</ul>
    </div>
    <div className="cards__wrapper">
        <ul className="cards__items">
            <CardItem 
            src="images/att.jpg"
            text="This website was built for information and display purposes and has a blog attached which can be updated by the client in an easy to use cms style. "
            label="ATT Floor Sanding"
            path="/att"
            />
             <CardItem 
            src="images/pop.jpg"
            text="This site is a work in progress, it's eventual aim is to provide a variety of recipes as well as nutrionional information."
            label="Pop in The Oven"
            path="/popintheoven"
            />

<CardItem 
            src="images/simplystaged.jpg"
            text="A Site for a local choir which has a lot of functionality for use for members and admins, including uploading and downloading material."
            label="simplyStaged.eu"
            path="/simplystaged"
            />
        </ul>
    </div>
</div>

        </div>
        
    )
}

export default Examples;