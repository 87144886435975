import React from 'react';
import '../../App.css';
import Footer from '../Footer';

import WebPages from "../WebPages"


function Cookies() {
return(

<>

    <WebPages
    head = "Our Cookie Policy"
    p1 = " A cookie is a small text file which is automatically saved on your computer when you visit our website and is used to help track activity across a website. The Coding Girls use cookies on our website for the following purposes: "
    
    li1 = "Google Analytics: We use Google Analytics to help provide anonymous web analytics for our website."
    li2 = "_ga -- used to distiguish users -- expiration 2 years"
    li3 = "_gat -- Used to throttle request rate -- expiration 30 minutes "
    li4 = "Website - we use cookies to help increase the user experience of this website."
    li5 = "seen-cookie-message -- Used to indicate you have seen the cookie message -- expiration	30 days"
    p3 = "Removing cookies: If you have any concerns about cookies, or want to remove cookies from your computer, please visit All About Cookies which explains how to do this."
    p2 = "Collection of personal information: If you contact us via a form on our website your personal information will only be used for us to contact you. No personal data you send us will be disclosed to a third party without your permission.If you wish us to remove any personal details we hold about you, please email us at maria@thecodingirls" 
    
    />
    
    <Footer />
    </>
);

}
export default Cookies;