import React from 'react';
import Navbar from './components/Navbar';
import{BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import'./App.css';
import Home from './components/pages/Home';
import WebDesign from './components/pages/WebDesign';
import Hosting from './components/pages/Hosting';
import Seo from './components/pages/Seo';
import Basic from './components/pages/Basic';
import WordPress from './components/pages/WordPress';
import Advanced from './components/pages/Advanced';

import About from './components/pages/About';
import ScrollToTop from './components/ScrollToTop';
import Cookies from './components/pages/Cookies';
import Hoster from './components/pages/Hoster';
import Contact from './components/pages/Contact';
import Examples from './components/pages/Examples';




function App() {
return(
    <>
   <Router>
     
   <Navbar />
   <ScrollToTop />
   <Switch>
       <Route path='/' exact component={Home} />
       <Route path='/WebDesign' exact component={WebDesign} />
       <Route path='/Hosting' exact component={Hosting} />
       <Route path='/Seo' exact component={Seo} />
       <Route path='/Basic' exact component={Basic} />
       <Route path='/WordPress' exact component={WordPress} />
       <Route path='/Advanced' exact component={Advanced} />
       <Route path='/About' exact component={About} />
       <Route path='/Cookies' exact component={Cookies} />
       <Route path='/Hoster' exact component={Hoster} />
       <Route path='/Contact' exact component={Contact} />
       <Route path='/Examples' exact component={Examples} />


       
    <Route path='/MakingLifeBeautiful' component={() => { 
     window.location.href = 'https://makinglifebeautiful.org'; 
     return null;
}}/>
     <Route path='/att' component={() => { 
     window.location.href = 'https://www.attfloorsanding.net'; 
     return null;
}}/>
<Route path='/handmadeinstotfold' component={() => { 
     window.location.href = 'https://www.handmadeinstotfold.com'; 
     return null;
}}/>
<Route path='/simplystaged' component={() => { 
     window.location.href = 'https://www.simplystaged.eu'; 
     return null;
}}/>
<Route path='/popintheoven' component={() => { 
     window.location.href = 'https://www.popintheoven.com'; 
     return null;
}}/>




      
   </Switch>
   </Router>
   

</>
); 

   

}

export default App ;
