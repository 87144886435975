import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import WebPages from "../WebPages"


function Basic() {
return(
<>
    <WebPages
    head = "A Basic Site - What is it?"
    p1 = "A Basic website is a collection of pages which offer the visitor information about your business, including social media links and email options."
    p2 = "A Basic site can accomplish the following:"
    li1 = "Pages with static or dynamic content."
    li2 = "Links to your social media accounts as well as displaying number of page likes and recommendations on your site."
    li3 = "Clients can email you direct from the site."
    li4 = "Mobile responsive."
    li5 = "Basic SEO implemented."
    p3 = "The basic website is ideal for small businesses who wish to capitalise on the customer reach the web can offer." 
    p4 = "Using the website like a shop front to get direct contacts from the web."
    />
    
    <Footer />
    </>
);

}
export default Basic;