import React from 'react';
import { Link } from 'react-router-dom';

import './Footer.css';

function Footer() {

return(
   <div className='footer-container'> 
    {/*  <section className="footer-subscription">
      <p className="footer-subscription-heading">
          Subscribe to my web design tips
          </p>
          <p className="footer-subscription-text">
              You can unsubscribe at anytime</p>
              <div className='input-areas'>
              <form>
              <input type="email" name="email" placeholder="Your Email"
              className="footer-input"/> 
            <Button buttonStyle='btn--outline'>Subscribe</Button>
            </form>
            </div>
   </section>  */}
   <div className="footer-links">
    <div className="footer-link-wrapper">
        <div className="footer-link-items">
            <h2>Services</h2>
            <Link to = '/Seo'>Seo</Link>
            <Link to = '/Hosting'>Hosting</Link>
            <Link to = '/WebDesign'>Web Design</Link>
            


        </div>
        <div className="footer-link-items">
            <h2>Our Policies</h2>
           
            <Link to = '/Cookies'>Cookies</Link>
            
            

        </div>
        
    
        <div className="footer-link-items">
            <h2>Pages </h2>
            <Link to = '/About'>About</Link>
            <Link to = '/'>Home</Link>
            <Link to = '/Contact'>Get in Touch</Link>

           


        </div>
       
        
    </div>
   </div>
   <section className="social-media">
<div className="social-media-wrap">
    <div className="footer-logo">
        <Link to ='/' className="social-logo">
        <img src="logo.jpg" alt="The coding girls"/> </Link>
        </div>  
       <small className='website-rights'>The Coding Girls</small> 
       <div className="social-icons">
           <Link className="social-icon-link facebook"
           to="/facebook"
           target="_blank"
           aria-label="Facebook">
               <i className="fab fa-facebook-f"></i>
           </Link>
           </div> 
    </div>


   </section>
   </div>

);
}

export default Footer;